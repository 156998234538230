
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.verified {
    // margin-top: 60px;
    @include media-breakpoint-up(sm) {
    	margin-top: 100px;
    }
    p {
        font-size: 18px;
        color: $textcolor;
        width:100%;
        text-align: center !important;
        a {
            color: $buttonblue;
            cursor: pointer;
        }
    }
}

