
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';


.giftActions {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 1090;
    background: $white;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin-left: -15px; //account for it's container at this size

    @include media-breakpoint-up(sm) {
        position: relative;
        margin-left: 0;
        box-shadow: none;
        display: block;
        z-index: 10;
    }

    .shareItem,
    .likeIt {
        width: 18%;
        color: $lightgrey;
        text-align: center;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            display: none !important;
        }
    }

    #pp {
        width: 100%;
		padding: 12px;
		display: none;
		@include media-breakpoint-up(sm) {
			display: block;
		}
        .likeItL,
        h4 {
            display: none;
            text-align: left;
			
            @include media-breakpoint-up(sm) {
                display: inline-block;
                padding: 0px 20px;
                width: 50%;
            }
        }
        .likeItL {
        	text-align: right;
        	i {
        		font-size: 22px;
        	}

        }
    }


    i.fas {
        color: $red;
    }

    i.far {
        color: $lightgrey;
    }

    i.fa-comments-o {
        color: $lightgrey;
    }

    .btn {
        border-radius: 30px;
        // border: 1px solid $formgrey;
        color: #fff;
        font-family: $cat;
        font-weight: 600;
        font-size: 17px;
        width: 60%;
        height: 50px;
        margin-left: 2%;
        margin-right: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $red;
        &:hover {
        	background-color: #fff;
        	border: 1px solid $red;
        	color: $red;
        }
        &.s {
        	border: 1px solid rgba(177, 176, 190, 0.4);
		    border-radius: 25px;
		    background-color: #fff;
		    color: #51506F;
		    &:hover {
		    	    border-color: #51506F;
		    }
        }
        @include media-breakpoint-up(sm) {
            width: 100%;
            margin: 0;
            margin-bottom:  20px;
        }
    }
}

