
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.profilePhoto {
    p {
        padding-left: 15px;
    }
}

div.cropCircle {
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: radial-gradient(transparent 70%, rgba(256, 256, 256, .5) 70%);
}

.progress {
    background-color: transparent;
}

a.crop.disabled {
    display: none !important;
}

