
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causePage {
    h3 {
        font-family: $cat;
        color: $bluetext;
    }

    @include media-breakpoint-up(sm) {
        padding-top: 15px;
    }

    .mainSection {
        padding: 10px 1%;
        position: relative;

        img {
            max-width: 100%;
            border-radius: 10px;
        }

        .likeCause {
            // display: none;
            position: absolute;
            right: 30px;
            top: 30px;
            padding: 7px 7px 6px 7px;
            cursor: pointer;

            &.liked {
                background-color: rgb(255, 255, 255, 0.7);
            }

            // @include media-breakpoint-up(sm) {

                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                background-color: #FFF;
                box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);

                // span {
                //     color: $bluetext;
                //     font-family: $cat;
                //     font-size: 12px;
                //     line-height: 18px;
                //     padding-left: 7px;
                //     text-transform: uppercase;
                //     font-weight: 600;
                // }

                i {
                    font-size: 22px;
                }

                i.fas {
                    color: $red;
                }

                i.far {
                    color: $bluetext;
                }
            
        }

        h1 {
            color: $bluetext;
            padding: 20px 8px 0 10px;

            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
        }

        .sub {
            color: $bluetext;
            font-size: 12px;
            width: 100%;

            font-weight: 600;
            padding-left: 10px;

            .loc {

                i {
                    color: $red;
                    padding-right: 1rem;

                }
            }

            .cat {

                padding-right: 1rem;
            }

            hr {
                color: $grey;
                margin-top: 2rem;
            }
        }

        .desc {
            width: 100%;
            padding: 10px;
            white-space: pre-line;
            color: $textcolor;
            font-family: $open-sans;
            font-size: 16px;
            line-height: 26px;
            position: relative;

            .more-text {
                display: none;
            }

            >.read-more {
                color: $buttonblue;
                display: flex;
                align-items: flex-end;
                position: relative;
                bottom: 100px;
                width: 100%;
                height: 100px;
                background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);

                >a.simple {
                    text-decoration: none;
                    max-width: 130px;
                    color: $grey;
                    font-family: $cat;
                    font-size: 15px;
                    position: absolute;
                    bottom: -75px;
                    text-align: center;
                }
            }
        }
    }

    .supporting {
        padding: 10px 1%;

        .supp {
            border-radius: 10px;
            padding-top: 5x;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);

            .supportLink {
                display: none;

                @include media-breakpoint-up(sm) {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding-top: 0;

                    &.b {
                        // justify-content: left;

                    }
                }

                width: 100%;
                text-align: center;
                padding: 15px 5%;

                button {
                    background-color: $red;
                    outline: none;
                    color: $white;
                    border: none;
                    width: 100%;
                    border-radius: 25px;
                    height: 50px;
                    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
                    font-family: $cat;

                    &:hover {
                        background-color: #fff;
                        border: 1px solid $red;
                        color: $red;
                    }
                }

                &.b {
                    button {
                        border: 1px solid rgba(177, 176, 190, 0.4);
                        border-radius: 25px;
                        background-color: #fff;
                        color: $grey;

                        &:hover {
                            border-color: #51506F
                        }
                    }
                }

                .likeItL {
                    width: 15%;
                    margin-left: 2%;
                }

                i {
                    font-size: 22px;
                }

                i.fas {
                    color: $red;
                }

                i.far {
                    color: $lightgrey;
                }
            }

        }


    }

    .nothingYet {
        color: $red;
        cursor: pointer;
    }


}

#einModalData {
    h2 {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    p,
    div {
        padding-top: 0;
        font-size: 14px;
        font-family: $open-sans;
        color: $textcolor;
    }
    div.data {
    	padding: 5px 0;
    }
    div.navigator {
    	font-size: 12px;
    }
}

#causeEin {
    width: 100%;
    display: flex;
    text-align: center;
    padding-top: 20px;

    .einContain {
        border-radius: 10px;
        border: 1px solid #E8EBF0;
        width: 100%;
        padding: 10px 0;

        a {
            color: #B8B8BF !important;
            text-transform: uppercase;
            cursor: pointer;
            font-family: $open-sans;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
        }

        span {
            margin-left: 7px;
        }
    }
}

