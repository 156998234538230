
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#publishBox {
    display: none;
    position: fixed;
    padding: 7px 0;
    z-index: 3000;
    top: 1px;
    padding-top: 5px;
    z-index: 3000;
    color: $textcolor;
    background-color: #fff;
    width: 300px;
    margin-left: -150px;
    left: 50%;
    border: 1px solid rgba(177, 176, 190, 0.4);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 22px 0 rgb(17 12 83 / 8%);

    >p {
        color: $red;
        margin-bottom: 5px;
    }

    >a.secondaryButton {
        margin-bottom: 3px;
        display: inline-block;

    }
}

