
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.blogIndex {
	max-width: 650px;
	.e{
		padding: 20px 0;
	}
	.rev {
		margin: 10px 0;
	}
}
