
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.myGiftsPage .secondaryButton {
    margin-left: 20px;
}

.desktop {
    @include media-breakpoint-down(xs) {
        display: none;
    }

    &.tablehead {
        border-radius: 12px 12px 0 0;
        background-color: rgba(194, 200, 216, 0.1);
        padding-bottom: 0;
        margin-bottom: 0;
        height: 44px;
        padding: 8px 20px 0 20px;

        div[class^="col"] {
            text-transform: uppercase;
            color: #A9A7B4;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 30px;
            padding-top: 8px;
        }

        div.col {
            padding-left: 5px;
        }
    }

    .point {
        cursor: pointer;
    }

    &.myItems {
        margin: 0 -15px 2px -15px;
        box-shadow: 1 1px 1px 1px rgba(17, 12, 83, 0.08);
        border-radius: 0;
        padding: 20px;

        div[class^="col"] {
            font-family: $open-sans;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: left;
        }

        div[class^="col"].img {
            justify-content: left;

            img {
                max-width: 25%;
            }

            .description {
                vertical-align: middle;
                display: inline-block;
                font-family: $open-sans;
                max-width: 73%;
                padding-left: 5px;

                .name a {
                    color: $bluetext;
                    font-family: $open-sans;
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 26px;
                }

                .desc {
                    color: #52506F;
                    ;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: normal;
                    font-family: $open-sans;
                    max-height: 75px;
                    overflow: hidden;
                }
            }

        }

    }

    img.icon.trash {
        background-color: $red;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
    }
}


.myItems {
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    border-radius: 10px;
    margin: 15px -5px;
    padding: 20px;

    .img {
        padding: 0px;

        img {
            max-width: 80px;
            height: 58px;
            border-radius: 8px;
        }

        margin-bottom: 15px;
    }

    .status {
        border: 1px solid $formgrey;
        border-radius: 25px;
        padding: 7px;
        height: 40px;
        width: 143px;
        padding-left: 15px;
        font-size: 14px;
        letter-spacing: 0;
        font-family: $open-sans;
        color: $bluetext;
    }
}

.myItems.mobile {
    @include media-breakpoint-up(sm) {
        display: none;
    }

    &.col-9 {
        padding-right: 0;
    }

    h3 {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .pc {
        width: 100%;
        overflow: visible;
        white-space: nowrap;

        span.price {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            text-align: left;
            display: inline-block;
            padding-right: 5px;
        }

        span.dot {
            text-align: left;
            padding-right: 5px;
        }

        span.category {
            color: #A9A7B4;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            text-transform: uppercase;
            display: inline-block;
            overflow: visible;
            white-space: nowrap;

            // &::before {
            //     content: ".";
            //     color: #A9A7B4;
            //     padding-right: 10px;
            // }
        }
    }



    img.icon {
        border: 1px solid $formgrey;
        border-radius: 50%;
        padding: 8px;
        height: 40px;

        &.edit,
        &.trash {
            height: 40px;
            padding: 11px;
        }

        &.trash {
            background-color: $red;
            color: fff;
            padding: 12px;
        }

    }


}


.fade-enter-active,
.fade-leave-active {
    transition: opacity .8s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

