.rcrop-wrapper {
    position: relative;
    display: inline-block;
    max-width: inherit;
}

.rcrop-wrapper img {
    display: block;
}

.rcrop-wrapper .rcrop-croparea {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.rcrop-wrapper .rcrop-croparea .rcrop-croparea-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.rcrop-wrapper .rcrop-outer-wrapper {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rcrop-wrapper .rcrop-outer {
    background: #fff;
    position: absolute;
}

.rcrop-wrapper .rcrop-outer.rcrop-outer-left {
    height: 100%;
    left: 0;
    top: 0;
}

.rcrop-wrapper .rcrop-outer.rcrop-outer-right {
    height: 100%;
    right: 0;
    top: 0;
}

.rcrop-wrapper .rcrop-outer.rcrop-outer-top {
    top: 0;
    width: 100%;
}

.rcrop-wrapper .rcrop-outer.rcrop-outer-bottom {
    bottom: 0;
    width: 100%;
}

.rcrop-wrapper .rcrop-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rcrop-wrapper .rcrop-grid-line {
    position: absolute;
}

.rcrop-wrapper .rcrop-grid-line:nth-child(1) {
    width: 100%;
    height: 33%;
    top: 33%;
    left: 0;
    border-top: 1px dashed #fff;
    border-bottom: 1px dashed #fff;
}

.rcrop-wrapper .rcrop-grid-line:nth-child(2) {
    height: 100%;
    width: 33%;
    left: 33%;
    top: 0;
    border-left: 1px dashed #fff;
    border-right: 1px dashed #fff;
}

.rcrop-wrapper .rcrop-handler-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-corner {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border: 1px solid #000;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-top-left {
    top: -3px;
    left: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-top-right {
    top: -3px;
    right: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-bottom-left {
    bottom: -3px;
    left: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-bottom-right {
    bottom: -3px;
    right: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-border {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border: 1px solid #000;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-top {
    top: -3px;
    left: 50%;
    margin-left: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-right {
    right: -3px;
    top: 50%;
    margin-top: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-bottom {
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
}

.rcrop-wrapper .rcrop-handler-wrapper .rcrop-handler-left {
    left: -3px;
    top: 50%;
    margin-top: -3px;
}

.rcrop-wrapper .clayfy-touch-device .rcrop-handler-bottom-right {
    display: none;
    width: 18px;
    height: 18px;
    bottom: -5px;
    right: -5px;
}

.clayfy-box:not(.clayfy-not-move) {
    cursor: move;
}

.clayfy-ghost {
    z-index: 1000;
}

.clayfy-ghost-opacity {
    opacity: .5;
}

.clayfy-handler {
    z-index: 1001;
}

.clayfy-handler.clayfy-top {
    cursor: n-resize;
}

.clayfy-handler.clayfy-top.left {
    cursor: nw-resize;
}

.clayfy-handler.clayfy-top.right {
    cursor: ne-resize;
}

.clayfy-handler.clayfy-bottom {
    cursor: n-resize;
}

.clayfy-handler.clayfy-bottom.left {
    cursor: sw-resize;
}

.clayfy-handler.clayfy-bottom.right {
    cursor: se-resize;
}

.clayfy-handler.clayfy-left {
    cursor: w-resize;
}

.clayfy-handler.clayfy-right {
    cursor: w-resize;
}

.clayfy-handler.clayfy-touch-device {
    background: #fff;
    border: 1px solid #000;
    border-radius: 3px;
}

.clayfy-sort-droparea {
    border: 2px solid #ccc;
}