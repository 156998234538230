
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causesSharedWith {
    .shareInputs {
        padding: 10px 0;

        >p:nth-child(3) {
            margin-bottom: 30px;
        }
    }

    .sharedWith {
        padding: 10px 0;
    }

    h2.title {
        padding: 0px;
    }

    a.secondaryButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
}


table.sharedCauses {
    display: inline-table;
    width: 100%;

    // white-space: pre-wrap;
    .first {
        max-width: 70%;
        white-space: pre-wrap;
    }

    .last {
        cursor: pointer;
        white-space: pre-line;
    }

    .status {
        &.y {
            &:before {
                content: "\2713";
                display: inline-block;
                color: #86af49;
                padding: 0 3px 0 0px;
            }
        }

        &.n {
            &:before {
                content: "x";
                font-weight: 600;
                display: inline-block;
                color: $red;
                padding: 0 3px 0 0px;
            }
        }
    }

    thead,
    tbody {
        width: 100%;
    }

    th,
    td {
        font-size: 16px;
    }

    th {
        text-transform: capitalize;
    }

    .statCol {
        display: none;

        @include media-breakpoint-up(md) {
            display: table-cell;
        }
    }
}

