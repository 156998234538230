
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    left: 0;
    font-family: $cat;
    flex-direction: column;
    // margin-top: 20px;
    background-color: transparent;
    box-sizing: border-box;
    // clear:both;
    // padding: 10px;
    opacity: 1;
    min-height: 60px;
    height: auto;

    ul {
        list-style: none;
        width: 100%;
        padding-left: 0;
        margin: 0;

        li {
            display: inline-block;

            a {
                color: $bluetext;
                font-size: 11px;
                outline: none;
            }

        }

        &.social {
            li {
                padding: 0 10px;
            }

            a {
                font-size: 25px;
            }
        }
    }

    .copy {
        font-size: 9px;
        padding-bottom: 10px;
    }
}

