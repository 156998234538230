
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
 #accountConversation .conversation .messageContainer.requesters {

    display: none;
    float: right;
    min-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    max-width: 480px;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    background-color: $blue;

    h4 {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
    }

    p {
        color: #52506F;
        font-family: $open-sans;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
        &.warn {
        	font-size: 12px;
        	color:$red;
        	line-height:12px;
        }
    }

    a.btn {
        border-radius: 21px;
        background-color: $buttonblue;
        color: #fff;
        font-family: $cat;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        padding: 6px 27px;
        max-width:200px;
        
        float: left;
    }
}

