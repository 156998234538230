
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

// See Blog.vue
//special for FAQ
#wpPostEmbed.faq {
	max-width: 750px;
}
#faq {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .howitworks {
        width: 100%;

        >div {
            border-radius: 10px 10px 12px 12px;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
            background-color: #fff;
            padding: 0 18px;
            width: 100%;
            
            margin-bottom: 20px;

            p {
                color: $textcolor;
                display: none;
                padding-bottom: 28px;
            }
        }
    }

    button {
        cursor: pointer;
        font-size: 18px;
        background-color: #fff;
        padding: 10px 0;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        font-family: $cat;
        color: $bluetext;

        span {
            float: right;
            padding-right: 5px;
        }

        span::before {
            content: "+";
            font-size: 22px;
            line-height: 22px;
        }

        &.active {
            span::before {
                content: "\2014";

            }
        }
    }



}

