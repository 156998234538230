
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#nextPayment {
	width:100%;
	h2 {
		padding-left: 15px;
	}
	>div {
		padding-left: 15px;
		font-family: $open-sans;
		color: $textcolor;
		.status {
			padding-bottom:20px;
		}
	}
	p {
		padding: 0 15px;
	}
	a.t{
		cursor: pointer;
		text-decoration: none;
	}
}
