
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.logins {
    max-width: 500px;
    margin-top: 50px;
}

.login {
    background-color: rgba(213, 231, 255, 0.5);
    height: auto;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.18);

    .card {
        background-color: transparent;
        border: none;
        text-align: center;
        padding: 5px;
        width: 100%;
        max-width: 360px;

        .card-head {
            // width:550px;
            color: $bluetext;
            font-family: $cat;
            font-size: 38px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 49px;
            text-align: center;
            padding: 20px 0 10px 0;

            &.reg {
                // margin-left: -20px;
            }
        }

        .card-body {
            background-color: transparent;
            border: none;
            // width: 360px;
            text-align: center;

            form {

                .form-group {
                    margin-bottom: 1px;

                    input {
                        border-radius: 0;
                        line-height: normal;

                        &.f {
                            border-radius: 10px 10px 0 0;
                        }

                        &.l {
                            border-radius: 0 0 10px 10px;
                        }

                        &.submit {
                            background-color: $buttonblue;
                            color: #FFFFFF;
                            font-family: $cat;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 21px;
                            text-align: center;
                            padding-left: 0px;
                            margin-top: 20px;
                            border-radius: 25px;
                        }
                    }

                }

            }

            p {

                color: #52506F;
                font-family: $open-sans;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: left;

                &.termsAgree {
                    font-size: 12px;
                    color: $grey;
                    padding-top: 10px;
                    padding-left:5px;
                    margin-bottom: -11px;
                    line-height: 30px;
                    


                    a {
                        width: auto;
                        height: auto;
                        color: $buttonblue;
                        padding-left: 0;
                        background-color: $blue;
                        font-size: 12px;
                        font-family: inherit;
                        line-height: 30px;
                        font-family: $open-sans;
                        vertical-align: top;
                    }
                }
            }
        }

        .form-group {
            position: relative;
            background-color: transparent;
            margin-bottom: 12px;
            text-align: center;

            &.e {
                margin-bottom: 3px;
            }

            &.ta {
                padding-bottom: 0
            }

            .error {
                text-align: center;
                color: $red;
                width: 100%;
                margin-bottom: 0;
            }

            &.or {

                span.d {
                    width: 45%;
                    overflow: hidden;
                    white-space: nowrap;
                    color: $lightgrey;
                    letter-spacing: 6px;
                    margin-top: -2px;
                }

                span.or {
                    width: 10%;
                    color: $lightgrey;
                    opacity: 0.45;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }

            i {
                position: absolute;
                color: $icongrey;
                top: 20px;
                left: 25px;

                &.fa-facebook-f {
                    background-color: #3B5999;
                    width: 20px;
                    height: 20px;
                    border-radius: 9px;
                    top: 15px;
                    padding-top: 4px;
                    color: $white;

                    &:before {
                        font-size: 16px;
                    }
                }
            }

            input,
            a {
                width: 100%;
                height: 50px;
                border-radius: 30px;
                padding-left: 54px;
                border: none;
                font-family: $cat;
                font-size: 16px;
                background-color: $white;
                color: $bluetext;
                outline: none;

                &.g {
                    background-image: url(https://d1812kujpqokfk.cloudfront.net/assets/google-g.png);
                    background-size: 5%;
                    background-position: 25px 16px;
                    background-repeat: no-repeat;
                    padding-left: 54px;
                    text-align: left;
                    color: $bluetext !important;
                    line-height: 50px;
                    font-family: $cat;
                }

                &.f {
                    padding-left: 54px;
                    text-align: left;
                    color: $bluetext !important;
                    line-height: 50px;
                }

                &::placeholder {
                    opacity: 0.45;
                    color: $grey;
                    font-family: $cat;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }

            a {
                vertical-align: middle;
                text-align: center;
            }

            .forgot-link {
                position: absolute;
                bottom: 18px;
                right: 32px;
                background: transparent;
                height: 22px;
                width: 60px;
                padding-left: 0;
                opacity: 0.45;
                color: $lightgrey !important;
                font-family: $cat;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                text-align: right;
                text-transform: uppercase;

            }
        }
    }

    .mem {
        p {
            color: $grey;
            font-family: $cat;

            a {
                color: $buttonblue;
                font-weight: 600;
            }
        }

    }

}

.altLogin {
    text-align: center;
    padding-top: 5px;

    img {
        max-width: 220px;
        height: auto;
    }
}

