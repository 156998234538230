
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.itemShare {
    display: none;
    padding: 20px;

    @include media-breakpoint-up(sm) {
        padding: 10px;
    }

    h3 {
        color: #120C53;
        font-family: $cat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;

    }

    @include media-breakpoint-up(sm) {
        display: block;
    }

    >div {
        display: flex;
        justify-content: center;
    }

    .network {
        display: flex;
        width: 25%;
        justify-content: center;
        // @include media-breakpoint-up(sm) {
        // 	width: 25%;

        // }
        span {
            display: flex;
            box-sizing: border-box;
            height: 50px;
            width: 50px;
            border: 1px solid $actiongrey;
            border-radius: 25px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;

            @include media-breakpoint-up(sm) {
                height: 40px;
                width: 40px;
            }

            .fa,
            .fab {
                color: $actiongrey;
                font-size: 20px;
            }


            .fa-twitter {
                color: #00acee;
            }

            .fa-facebook {
                color: #3b5998;
            }

            .fa-envelope {
                color: $grey;
            }

            .fa-whatsapp {
                color: #25d366;
            }



        }


    }

    span {}
}

