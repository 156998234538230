
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#steps, #g-steps {
    width: 100%;

    ul {
        list-style: none;
        width: 100%;
        padding: 15px;
        margin-bottom: 0;
    }

    li {
        display: inline-block;
        width: 32%;
        text-align: center;
        cursor: pointer;
        &.active {
                div {
                	color: $buttonblue;
                }
                span {
                	color: $buttonblue;
                	border-color: $buttonblue;
                }
            }
        div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $open-sans;
            color: #D2D1E5;
            font-size:13px;
            font-weight: 600;
            &:last-child {
            	padding-top:5px;
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            border: 1px solid #D2D1E5;
            color: #D2D1E5;
            font-family: $cat;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;

            i {
                font-size: 0.7em;
            }

            

        }
    }
}

