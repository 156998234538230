
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.supporters {
    font-family: $cat;
    font-size: 16px;
    color: $grey;
    position: relative;
    min-height:100px;
    margin-top:15px;
    .vld-background {
		background-color: #fff;
	}
	p{
		text-align: center;
		width: 100%;
	}
    img {
        width: 50px;
        height: 50px;
    }

    >div {
        display: flex;
        padding: 12px 0;
    }

    .count {
        span {
            font-weight: 600;
        }
    }

    .icon {
        display: inline-block;
        height: 100%;

        .anon {}

        .user {
            border-radius: 50%;
        }
    }

    .detail {
        height: 100%;
        display: inline-block;
        padding-left: 20px;

        div {
            display: block;

            &.what {
                font-family: $cat;
                font-size: 12px;
                text-transform: uppercase;
                color: $lightgrey;

                span {
                    font-family: $open-sans; // $ sign looks wierd in cat
                }
            }
        }
    }
}

