
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.accountContainer {
    .account {
        padding: 15px;

        .person {
            display: flex;
            flex-direction: row;
            position: relative;

            img {
                width: 75px;
                height: 75px;
                border-radius: 50%;
            }

            .edit {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 50px;
                left: 50px;
                z-index: 2;
                background-color: $buttonblue;
                border-radius: 50%;
                border: 2px solid #fff;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: #fff;
                }
            }

            .user {
                h2 {
                    padding: 0 15px;
                    margin: 0;
                }

                span {
                    width: 100%;
                    padding: 0 15px;
                    opacity: 0.9;
                    text-transform: uppercase;
                    color: $dategrey;
                    font-family: $open-sans;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
        }

        .menu {
            padding-top: 10px;

            .heading {
                color: $bluetext;
                font-family: $cat;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                padding: 5px 10px;
            }

            .settings-links,
            .activity-links {
                padding: 5px 0;
            }
        }
    }

    .col-sm-9 {
        padding-top: 5px; // note the h2.title has the other 10px
    }

    a.account-link {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        width: 100%;
        display: block;
        margin-bottom: 25px;

        img {
            margin-right: 16px;
            margin-left: 9px;
            border-radius: 7px;
        }

        span.mc.mob {
        	display: inline-block;
            background-color: $red;
            color: #fff !important;
            // padding:3px;
            border-radius: 50%;
            position: relative;
            left: -5px;
            text-align: center;
            top: -8px;
            // right: -3px;
            font-size: 11px;
            line-height: 12px;
            width: 12px;
            height: 12px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.back {
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    border: 1px solid rgba(177, 176, 190, 0.4);
    border-radius: 25px;
    color: $bluetext;
    font-family: $cat;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

