
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causePhoto {
    max-width: 750px;

    .currentCauseImage {
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
            border-radius: 13px;
        }
    }

    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        padding-left: 15px;
    }

    p {
        color: $textcolor;
        // font-family: $open-sans;
        font-weight: normal;
        font-size: 15px;
        padding-left: 15px;
        padding-top: 10px;
    }


}

.progress {
    margin-top: 5px;
}

#progress-bar {
    background-color: darken($blue, 10%) !important;
}

#drop-zone {

    width: 100%;

    margin-bottom: 20px;
    padding: 10px;

    &.noBorder {
        border: none;
    }

    .file-upload {
        &.highlight {
            border: 2px dashed $bluetext;
        }

        width: 100%;
        display: inline-block;
        height: 100%;
        min-height:130px;
        background-color:$blue;
        border: 1px dashed $bluetext;
        border-radius: 20px;
        padding:10px;
        position: relative;
        cursor: pointer;
        text-align: center;

        &:before {
            font-family: 'FontAwesome';
            content: "\f382";
            left: 50%;
            top: 60%;
            color: darken($blue, 6%);
            position: absolute;
            font-size: 27px;
            margin-left: -16.370px;
            margin-top: 0px;


        }

        input[type="file"] {
            visibility: hidden;
            height: 100%;
            margin: -10px;
        }
    }
}

#drop-zone.highlight {
    border-color: $bluetext;
}

p {
    margin-top: 0;
}

#picture {
    width: 100%;
    max-width: 100%;
    max-height: 100%;

    height: auto;
}

.changePhoto {
    display: none;
}

