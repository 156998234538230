
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.giftItem {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;

    a,
    a:hover {
        text-decoration: none;
    }
    a.disabled {
    	cursor: auto;
    }
    .card {
        margin-bottom: 10px;
        border: none;
        height: auto;
        border: none;
        border-radius: 10px 10px 12px 12px;
        text-align: center;
        box-shadow: 0 2px 22px 0 $boxshade;

        .ship {
            img {
                padding: 7px;
            }
        }

        .card-body {
            position: relative;
            padding: 0;
            
            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 10px 10px 0px 0px;
            }
            .soldItem {
            	position: absolute;
            	background-color: $red;
            	top:10px;
            	left:10px;
            	padding: 4px 12px;
            	color:#fff;
            	font-size:10px;
            	line-height: 18px;
            	font-family: $cat;
            	border-radius: 20px;
            	z-index: 100;
            }
            .inset {
                position: relative;
                display: flex;
                max-width: 91%;
                top: -16px;
                left: 10px;
                border-radius: 13px;
                background-color: #fff;
                height: 28px;
                padding: 2px 2px;

                div {
                    display: inline;
                    font-family: $cat;
                    text-transform: uppercase;
                    padding: 4px 12px;
                    font-size: 11px;
                    background-color: $greybg;
                    color: $bluetext;
                    border-radius: 13px;
                    height: 22px;
                    white-space: nowrap;
                    font-weight:600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-title {
                font-family: $cat;
                color: $bluetext;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 28px;
                padding: 0px 10px;
                width: 100%;
                text-align: left;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            div.desc {
                color: $textcolor;
                width: 100%;
                font-family: $open-sans;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: left;
                height: 64px;
                padding: 8px 10px;
                overflow: hidden;
                word-break: break-word;
            }

            .card-price {
                font-size: 16px;
                font-family: $open-sans;
                font-weight: 600;
                color: $bluetext;
                padding: 10px 0 10px 10px;
            }

            .supporting {
                font-size: 12px;
            }
        }
    }

    div.likeIt {
        width: 32px;
        height: 32px;
        z-index: 1000;
        padding: 10px 10px 10px 0;
        cursor: pointer;
        i.fas {
            color: $red;
        }

        i.far {
            color: $lightgrey;
        }
    }
}

