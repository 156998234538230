
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.forgot .login {
    .card {
        .card-body {
            form {
                .form-group {
                    input {
                        border-radius: 25px;
                    }
                }
            }
        }
    }
}

