
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.searchBox {
    position: relative;
    width: 100%;
    top: 10px;
    left: 50%;
    margin-left: -50%;
    z-index:1070;
    @include media-breakpoint-up(sm) {
    	width:500px;
    	margin-left: -250px;
    }

    #searchInput {
        box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.12);
        border: none;
        border-radius: 30px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding-left: 45px;
        color: $bluetext;
        outline: none;
        background-image: url(https://d1812kujpqokfk.cloudfront.net/assets/search.svg);
        background-repeat: no-repeat;
        background-position-x: 20px;
        background-position-y: 16px;
        background-size: 7%;
        &::placeholder {
            font-size: 16px;
        }
        @include media-breakpoint-up(sm) {
        	background-size: 5%;
        	background-position-x: 25px;
        	background-position-y: 14px;
        	padding-left: 50px;
        }
        
    }
}

#searchBoxSubmit {
    display: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    border: 1px solid #4b9b78;
    border-radius: 3px;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 15px 25px;
    // margin: 0 0 10px 25px;
    list-style: none;
    background-color: #ffffff;
    border:none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    font-family: $cat;

}

// .ui-menu-item>a.ui-corner-all {
//     display: block;
//     // padding: 3px 15px;
//     clear: both;
//     font-weight: normal;
//     line-height: 18px;
//     color: $bluetext;
//     white-space: nowrap;
//     text-decoration: none;
    

// }
.ui-menu-item {
	padding: 0 10px;
	cursor: pointer;
	border-radius: 20px;
	>div {
		padding:5px 10px;
		border-radius:20px;
	}
}
.ui-autocomplete-category {
	font-weight: 600;
	padding-left: 10px;
}
.ui-state-hover,
.ui-state-active {
    color: $bluetext;
    text-decoration: none;
    background-color: $blue;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}
.ui-helper-hidden-accessible{
    display:none !important;
}
