.ct.forms {
    .form-control:disabled {
        background-color: lighten(#e9ecef, 2%);
    }

    input,
    textarea,
    select {
        border-radius: 30px;
        border: 1px solid $formgrey;
        height: 50px;
        color: $grey;
        font-family: $cat;
        outline: none;

        &::placeholder {
            opacity: 0.51;
        }

        font-size: 17px;
        margin-bottom: 5px;
        padding-left: 25px;

        &.e {
            border: $red solid 1px;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        color: $grey;
        font-family: $cat;
        background-color: #fff;
    }

    input[type="radio"] {
        margin-bottom: 0;
    }

    label.magic {
        display: none;
        position: absolute;
        color: $lightgrey;
        top: -10px;
        left: 50px;
        font-size: 13px;
        font-weight: 600;
        z-index: 10;
        background-color: #fff;
        padding: 0 12px;
        border-radius: 12px;
    }

    label.magic:after {
        content: '\2713';
        display: inline-block;
        color: $green;
        padding: 0 0px 0 3px;
    }

    select:invalid {
        opacity: 0.51;
    }

    input[name="state"] {
        text-transform: uppercase;

        &::placeholder {
            text-transform: none
        }

        ;
    }

    select {
        color: $grey;
        -webkit-appearance: menulist;

        &::-ms-expand {
            display: block;
        }

        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzUxNTA2Rjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
        background-position: right 10px top;
        -moz-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    textarea {
        min-height: 180px;

    }

    p.radio,
    label.radio {
        color: $grey;
        opacity: 0.51;

        &.selected {
            opacity: 1;
        }
    }

    .form-group a.btn-primary,
    .modal-footer button.btn-primary,
    #goDonate,.showFilters a {
        border-radius: 30px;
        background-color: $red;
        color: #fff;
        width: 100%;
        padding: 12px;
        height: 50px;
        font-family: $cat;
        font-weight: 600;
        box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.12);
        font-size: 1rem;

        &:hover {
            background-color: #fff;
            border: 1px solid $red;
            color: $red;
        }

        &.simple {
            background: #fff;
            color: $grey;
            // border-color: rgba(177, 176, 190, 0.4);
            border: 1px solid rgba(177, 176, 190, 0.4);

            &:hover {
                border-color: $grey;
            }
        }
    }

    .modal-footer button.btn-primary {
        min-width: 120px;
        max-width: 90%;

        @include media-breakpoint-up(sm) {
            max-width: 44%;
        }
    }

    div.radiocheck div[data-toggle="tooltip"],
    div[data-toggle="tooltip"].tax {
        z-index: 1050;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 10px;
        right: 10px;
        height: 17px;
        width: 17px;
        line-height: 17px;
        background-color: $blue;
        border-radius: 10px;
        font-family: 'Times New Roman';
        font-style: italic;
        color: $buttonblue;
        text-transform: lowercase;

    }

    div.radiocheck {
        width: 100%;
        position: relative;
        border: 1px solid $formgrey;
        border-radius: 18px;
        height: 40px;
        display: flex;
        align-items: center;



        label {
            display: block;
            position: relative;
            padding-left: 35px;
            padding-top: 10px;
            cursor: pointer;
            font-size: 12px;
            line-height: 18px;
            opacity: 1;
            text-transform: uppercase;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            color: $bluetext;
            font-family: $cat;
            font-weight: bold;

            a.terms {
                color: $buttonblue;
            }

            img {
                height: 25px;
                width: auto;

            }



            &:hover input~span {
                background-color: #ccc;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked~span {
                    background-color: $buttonblue;
                }

                &:checked~span:after {
                    display: block;
                }
            }

            span {
                position: absolute;
                top: 10px;
                left: 10px;
                height: 17px;
                width: 17px;
                line-height: 25px;
                background-color: $icongrey;
                border-radius: 12px;

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 6px;
                    top: 2px;
                    width: 5px;
                    height: 11px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }

    div.empty {
        text-align: center;
        padding-top: 5px;

        .nothing {
            padding-top: 10px;
        }
    }
}

.tooltip {
    pointer-events: none; // stops flickering
}

@import 'rcrop';
