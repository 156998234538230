
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#giftPage {
    width: 100%;
    float: left;

    div.empty {
        display: flex;
        flex-direction: column;
        padding-top: 80px;

        @include media-breakpoint-up(sm) {
            padding-top: 110px;
        }

        align-items: center;

        img {
            padding-bottom: 40px;
        }

        a {
            max-width: 140px;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }

        p.nothing {
            padding: 0 25px;

            @include media-breakpoint-up(sm) {
                padding: 0 25%;
            }

            color: #52506F;
            font-family: $open-sans;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
        }
    }

    .col-sm-8 {
        padding: 0 2%;
    }

    h1,
    h3.sameOwner {
        color: $bluetext;
        padding: 20px 8px 0 10px;
        font-family: $cat;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
    }

    h3.sameOwner {
        padding-bottom: 15px;
    }

    h4 {
        color: $bluetext;
        font-family: $open-sans;
        padding: 0px 8px 0 10px;
        font-size: 18px;
        font-weight: 600;

        &.g {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

    }

    .sub {
        color: $bluetext;
        font-size: 12px;
        width: 100%;
        text-transform: uppercase;
        font-weight: 600;
        padding-left: 10px;

        .loc {

            i {
                color: $red;
                padding-right: 1rem;
            }
        }

        .cat {

            padding-right: 1rem;
        }

        hr {
            color: $grey;
            margin-top: 3rem;

            &.top {
                margin-top: 0;
            }
        }
    }

    .desc {
        width: 100%;
        padding: 10px;
        color: $textcolor;
        white-space: pre-line;
        font-family: $open-sans;
        font-size: 16px;
        line-height: 26px;
    }

    .mobile {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    .desktop {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }

        .contain {
            border-radius: 10px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
            padding: 10px 5px;
            margin-top: 20px;
        }
    }
}

