
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#filters {
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    >div.showFilters {
        display: flex;
        margin: -10px 0 10px 0;

        >a {
            max-width: 170px;
            text-align: center;
            height: 32px;
            text-decoration: none;
            line-height: 10px;

            &.active {
                border-color: lighten($red, 15%);
            }
        }

        @include media-breakpoint-up(md) {
            display: none;

        }
    }

    >div {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        >div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: $grey;
            border-radius: 25px;

            padding: 0 10px;
            height: 40px;
            border: 1px solid rgba(177, 176, 190, 0.4);

            cursor: pointer;
            width: 100%;
            margin: 5px;

            @include media-breakpoint-up(md) {
                width: 170px;
                margin: 0 5px;
            }

            .label {
                width: 100%;
                text-align: center;
            }

            &.cat {
                @include media-breakpoint-up(md) {
                    width: auto;
                }

                min-width: 170px;
            }

            span {
                color: $textcolor;

                &:first-child {
                    color: rgba(18, 12, 83, 0.45);
                }
            }

            &.active {
                border: 1px solid lighten($red, 20%);
            }

            div.removeFilter {
                position: absolute;
                right: 1px;
                top: -12px;
                border-radius: 50%;
                color: #FF5F58;
                padding: 0px 5px;
                z-index: 1000;
                background-color: #fff2f1;
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .popout {
        display: none;

        background-color: #fff;
        position: absolute;
        border: 1px solid rgba(177, 176, 190, 0.4);
        border-radius: 15px;
        padding: 15px 10px;
        top: 42px;
        left: 0px;
        z-index: 2000;
        color: rgba(18, 12, 83, 0.45);
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 280px;
        }
    }

    // .cities {
    //     width: 100%;

    //     @include media-breakpoint-up(md) {
    //         width: auto;
    //     }

    //     #cities {
    //         height: 40px;
    //         border: 1px solid rgba(177, 176, 190, 0.4);
    //         border-radius: 15px;
    //         outline: none;
    //         width: 100%;
    //     }
    // }

    .slider,
    .location {

        p {
            margin-bottom: 7px;
        }

        >.label {
            line-height: 18px;
        }

        input[type="number"],
        .btn {
            border-radius: 25px;
            width: 100px;
            height: auto;
            border: 1px solid rgba(177, 176, 190, 0.4);
            color: rgba(18, 12, 83, 0.45);
            padding-left: 15px;
        }

        input[type="radio"] {
            height: 25px;
        }

        .btn {
            background-color: $buttonblue;
            color: #fff;
            // min-width: 100px;
        }

        input[type=range] {
            position: relative;
            left: 0;
            bottom: -12px;
            font-size: 18px;
            -webkit-appearance: none;
            width: 100%;
            padding: 0 10px;
            border: none;
            height: 0;

            &.s {
                top: -12px;
            }
        }




        input[type=range]:focus,
        input[type=number]:focus {
            outline: none;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: $greybg;
        }

        input[type=range]:focus::-ms-fill-lower {
            background: $greybg;
        }

        input[type=range]:focus::-ms-fill-upper {
            background: $greybg;
        }

        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-webkit-slider-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $red;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: $red;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -7px;
        }

        input[type=range]::-moz-range-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-moz-range-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $greybg;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: #a1d0ff;
            cursor: pointer;
        }

        input[type=range]::-ms-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        input[type=range]::-ms-fill-lower,
        input[type=range]::-ms-fill-upper {
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-ms-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $greybg;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: #a1d0ff;
            cursor: pointer;

        }


    }

    .location {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .shipping {
        position: relative;
        min-width: 130px;

        @include media-breakpoint-up(md) {
            span.delivery {
                display: none;
            }

            width:70px;
            min-width:70px;

            >.label {
                text-align: left;
            }
        }

        @include media-breakpoint-up(lg) {
            span.delivery {
                display: inline;
            }

            width:170px;

            >.label {
                text-align: center;
            }
        }

        &:hover input~span {
            background-color: #ccc;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked~span {
                background-color: $buttonblue;
            }

            &:checked~span:after {
                display: block;
            }
        }

        .check {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 17px;
            width: 17px;
            line-height: 25px;
            background-color: $icongrey;
            border-radius: 12px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                left: 6px;
                top: 2px;
                width: 5px;
                height: 11px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .resetAll {
        width: 40px;
        border-color: lighten($red, 20%);

    }
}

