
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.col-sm.causeItem {
    margin-bottom: 10px;

    .card {
        height: auto;
        border: none;
        border-radius: 10px 10px 12px 12px;
        text-align: center;
        box-shadow: 0 2px 22px 0 $boxshade;

        a:hover {
            text-decoration: none;
        }

        .card-body {
            position: relative;
            padding: 0;

            .likeCause {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }

            i {
                font-size: 18px;
            }

            i.fas {
                color: $red;

            }

            i.far {
                color: #fff;
            }

            h4.card-title {
                margin-top: 0px;
                font-family: $cat;
                color: $bluetext;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                padding: 0px 18px;
                width: 100%;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .desc {
                text-align: left;
                color: $textcolor;
                font-family: $open-sans;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 23px;
                padding: 8px 18px;
                height: 82px;
                overflow: hidden;
                word-break: break-word;
            }
        }

        .inset {
            position: relative;
            display: flex;
            top: -16px;
            left: 18px;
            border-radius: 13px;
            background-color: #fff;
            height: 32px;
            padding: 2px;
            max-width: 80%;

            div {
                display: inline;
                text-transform: uppercase;
                padding: 5px 20px;
                font-size: 11px;
                font-family: $cat;
                font-weight: 600;
                background-color: $greybg;
                color: $bluetext;
                border-radius: 13px;
                height: 26px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        img {
            display: block;
            width: 100%;


            height: auto;
            border-radius: 10px 10px 0px 0px;
        }
    }

    div.likeIt {
        width: 32px;
        height: 32px;
        z-index: 1000;
        background: url(https://d1812kujpqokfk.cloudfront.net/assets/001-like.png);

        &.liked {
            background: url(https://d1812kujpqokfk.cloudfront.net/assets/002-heart.png);
            background-size: 100% 100%;
        }
    }
}

