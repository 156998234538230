
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#privacyContainer {
    padding: 20px;
    width: 100%;

    >div {
        width: 100%;
    }
}

