
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#supportChoices {
    .modal-content {
    	border-radius: 12px;
    }
    .modal-body {

    	.direct, .addItem {
    		width: 100%;
    		padding:5%;
    		h4 {
    			font-size: 20px;
    			color: $bluetext;
    			font-family: $cat;
    			font-weight: 600;
    		}
    		p{
    			font-size: 16px;
    			color:$textcolor;
    			font-family: $open-sans;
    		}
    		a {
    			color:$red;
    			text-transform: uppercase;
    			cursor: pointer;
    			text-decoration: none;
    			line-height: 25px;
    		}
    		i {
    			padding-left:10px;
    			font-size:0.9em;
    		}
    	}
    }
}

