
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.directLink {
    position: relative;

    .copied {
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 40%;
        top: 16%;
        padding: 10px;
        color: $red;
        z-index: 1000;
        border: 1px solid $blue;
        border-radius: 8px;
        display: none;
        width: 80px !important;
        height: 35px !important;
        background-color: $blue;
    }
}

