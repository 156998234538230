
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

button[disabled] {
    background-color: $grey;
}

.StripeElement {
    box-sizing: border-box;
    height: 50px;
    padding: 15px 12px;
    border: 1px solid $formgrey;
    border-radius: 18px;
    background-color: $white;
    width: 100%;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#card-errors, #terms-error {
    width: 100%;
    font-weight:bold;
    font-size: 15px;
    color: $red;
    padding:3px 10px;
}

.payment_card {
    .row {
        &.pay {
            button {
                background-color: $red;
                color: #fff;
                height: 50px;
                width: 100%;
                border-radius: 20px;
                font-family: $cat;
                font-weight: 600;
                font-size: 15px;
            }
        }

        padding: 5px;

        h3 {
            color: $bluetext;
            font-size: 18px;
            line-height: 30px;
            padding-left: 10px;
        }
    }

}

#payment-request-button {
    border: none;
    padding: 5px 0;

    iframe {
        border-radius: 20px;
    }
}

.payment_card .messaging {
    width: 100%;
    text-align: center;
    background-color: #fff;
    margin-top: 0 10px;
    padding: 15px;
    border-radius:10px;
    border: 1px solid $formgrey;
    p {
    	font-family: $open-sans;
    	color: $bluetext;
    	font-size: 18px;
    	a {
    		color: $buttonblue;
    	}
    	&.thanks {
    		font-size: 20px;
    		font-family: $cat;
    	}
    }
    .onward {
    	display: flex;
    	padding: 0 5px;
    	>a {
    		margin: 0 5px;
    	}
    }
    .itemShare {
    	padding: 20px 5px;
    	display: block;
     h3{
    	text-transform: capitalize;
    }
}
    &.error {
        background-color: $red;
        p {
        	color: #fff;
        }
        
    }
}
.ct.forms .saved_cards {
	.radiocheck {
		margin-bottom:10px;
	}
	.or {
		font-family: $open-sans;
		color: $bluetext;
		padding-left:20px;
	}
}
.ct.forms .row.apple {
	padding:5px 15px;
}
