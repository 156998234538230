
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

// see myItems in Gifts.vue
// some shared styles
.myOrders {
    .desktop.myItems {
        @include media-breakpoint-down(xs) {
            display: none;
        }

        .col-2.status {
        	border: none;
        	padding: 0 15px;
        	height: auto;
            a {
                color: $bluetext;
                font-family: $open-sans;
                text-transform: capitalize;
            }

            span {
                display: none;
            }
        }
        .col-2.receipt {
        	a {
        		color: $bluetext;
        		cursor: pointer;
        	}
        }
    }

    .mobile {
        .col-12 {
            border-bottom: 1px solid $formgrey;
            margin-bottom: 15px;
            padding-left: 0;
            padding-right: 0;
        }

        .col-6 {
            padding-left: 10px;
            padding-right: 10px;
        }

        .orderstatus,
        .stateDate {
            display: inline-block;
            text-transform: uppercase;
            opacity: 0.9;
            color: $bluetext;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 18px;

        }

        .orderstatus {
            width: 45%;

            a {
                color: $bluetext;
            }

            span {
                color: $brightgreen;
                padding-right: 10px;
            }
        }

        .stateDate {
            width: 51%;
            color: #A9A7B4;
            text-align: right;
            font-weight: 600;
        }

        .receipt {
            width: 100%;
            text-align: center;
            height: 30px;
            border: 1px solid rgba(177, 176, 190, 0.2);
            border-radius: 20.5px;
            margin-left: -15px;

            a {

                color: $bluetext;
                font-family: $cat;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .orderDetails {
            width: 100%;
            text-align: center;
            height: 30px;
            background-color: $buttonblue;
            border-radius: 20.5px;
            margin-right: -15px;

            a {

                color: #fff;
                font-family: $cat;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}

