
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#connectedPayouts,
#nextPayment {
    margin-bottom: 20px;
    width: 100%;

    .title,
    p {
        padding-left: 15px;
    }

    table {
        display: block;
        margin: 10px;
        width: 100%;

        border: 1 px solid $formgrey;
        table-layout: fixed
    }

    thead,
    tbody {
        width: 100%;

        tr {

            th,
            td {
                width: auto;

            }

            th.last,
            th.first,
            td.last,
            td.first {
                width: auto;
            }
        }
    }
}

