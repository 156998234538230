
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.videoEmbed {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: -80px;
    margin-bottom:20px;
	@include media-breakpoint-up(sm) {
		margin-top: -100px;
     }
     @include media-breakpoint-up(md) {
		margin-top: -175px;
     }
    video,
    #playVid {

        width: 90%;
        height: auto;
        border-radius: 5px;
        max-width: 640px;
        object-fit: cover;
    }

    #playVid {
        width: 62px;
        height: 62px;
        margin-left: -31px;
        margin-top: -33px;
        background-color: $red;
        border-radius: 50%;
        z-index: 100;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            width: 100px;
            height: 100px;
            margin-left: -50px;
            margin-top: -50px;
        }

        >div {
            width: 0;
            height: 0;
            margin-left: 3px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #fff;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;

            @include media-breakpoint-up(sm) {
                margin-left: 6px;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 12px solid #fff;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

        }
    }
}
#howHead {
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;


    h1 {
        width: 100%;
        text-align: center;
        font-size: 28px;
        padding: 20px 0;
         @include media-breakpoint-up(sm) {
         	font-size: 35px;
         	 padding: 30px 0;
         }
          @include media-breakpoint-up(md) {
          	font-size: 45px;
         	 padding: 40px 0;
          }
    }

    h2 {
        text-align: center;
        font-size: 16px;
        max-width: 550px;
        font-family: $cat;
        color: $bluetext;
        line-height: 21px;
        padding: 0 15px 65px 15px;

         @include media-breakpoint-up(sm) {
         	font-size: 17px;
         	line-height: 25px;
         }
         @include media-breakpoint-up(md) {
         	font-size: 18px;
         	line-height: 27px;
         }
    }

    >.contain {
        background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/jbpanda.png'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/bulb.svg'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/cc.svg'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/moneypot.svg'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/red.svg'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/stars.svg'),
            url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/npug.png');
        background-attachment: fixed;
        background-position: 82% 15%,
            7% 41%,
            97% 35%,
            1% 9%,
            86% 67%,
            36% 56%,
            50% 30%;
        background-repeat: no-repeat;
        background-size: 4%,
            4%,
            4%,
            4%,
            2%,
            4%,
            5%;


        width: 100%;
        padding-top: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $blue;
        // padding-bottom: 60px;

        @include media-breakpoint-up(sm) {
            background-position: 82% 15%, 7% 41%, 97% 35%, 1% 9%, 86% 67%, 36% 56%, 50% 20%;
            background-size: 3%, 3%, 3%, 3%, 1%, 3%, 4%;
             // padding-bottom: 100px;
        }
        @include media-breakpoint-up(md) {
        	// padding-bottom: 200px;
        }
    }

    img {

        max-width: 90%;
        margin-top: -60px;
        margin-bottom: 20px;
         @include media-breakpoint-up(sm) {
         	max-width: 85%;
        	  margin-top: -100px;
        	margin-bottom: 40px;
         }
        @include media-breakpoint-up(md) {
        	max-width: 80%;
        	  margin-top: -200px;
        	// margin-bottom: 40px;
        }
    }
}

body.how {
    #app {
        padding: 0;
        width: 100%;
    }

    .mainContain {
        background: transparent;
        // background-color: transparent;
        padding-left: 15px;
        padding-right: 15px;
    }

    #howHead {
        display: flex;


    }
}

