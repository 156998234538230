
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.shipping {
    h3 {
        font-size: 20px;
        font-family: $cat;
        color: $bluetext;
        padding-bottom:10px;
    }

    .quotes {
        max-width: 600px;
        justify-content: center;

        label {
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;

            input[type="checkbox"].form-control {
                height: 17px;
                width: 17px;
                display: inline;
                margin: 0;
                margin-right: 10px;
                padding-top: 3px;
            }

            a {
                color: $buttonblue;
            }
        }


        .item {
            padding: 15px;
            color: $bluetext;
            font-family: $cat;
            font-size: 16px;

            .container {
                img {
                    max-width: 60px;
                    height: auto;
                }

                span {
                    padding: 5px;

                    &.price {
                        font-weight: 600;
                        font-family: $open-sans;
                    }
                }

                p {
                    font-family: $open-sans;
                }
            }
        }
        .btns {
        	display:flex;
        	padding: 0 5px;
        	a {
        		margin:5px;
        		max-width: 93%;

        		@include media-breakpoint-up(sm) {
        			max-width: 45%;
        		}
        	}
        }
        // a.btn-primary {
        //     margin-bottom: 10px;

        //     @include media-breakpoint-up(sm) {
        //         min-width: 120px;
        //         max-width: 44%;
        //     }
        // }
    }
}

