
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#stripeConnect {
	h2.title {
		padding-left: 15px;
	}
	.causeTotals {
		h3{
			margin-bottom: 0;
		}
		padding-left:15px;
		>p {
			font-size: 18px;
			font-weight:600;
			padding-bottom: 3px;
			margin-bottom: 0;
			>span {
				font-size: 16px;
				font-weight:normal;
			}
		}
	}
    // max-width: 700px;

    ul {
        padding-inline-start: 20px;
        font-family: $open-sans;
        color: $textcolor;

        li {
            text-align: left;
        }
    }

    .step,
    .step1,
    .panel {
        padding: 15px;
        color: $textcolor;
        text-align: center;

        p {
            text-align: left;
        }

        // a.btn {
        //     color: $bluetext;
        // }
    }

    .step1 {
        background-color: #fff;

        .empty {
            padding-top: 1% !important;
        }
    }
    .step .attention {
    	color: $red;
    }
}

