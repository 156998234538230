
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

body.homeOnly {
    nav.navbar-custom #navbarNavDropdown .nav-item {
        @include media-breakpoint-up(lg) {
            width: 33%;
        }
    }
}

.searchFromNav {
    width: 300px;
    margin-left: -150px;
    position: fixed;
    top: 150px;
    left: 50%;
    z-index: 2000;
    display: none;
}

nav.navbar-custom {
    background-color: #fff;
    z-index: 1100;

    a span.mc {
        display: none;

        background-color: $red;
        color: #fff !important;
        // padding:3px;
        border-radius: 50%;
        position: relative;
        left: -10px;
        text-align: center;
        top: -9px;
        // right: -3px;
        font-size: 11px;
        line-height: 12px;
        width: 12px;
        height: 12px;

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    .mobileSearch {
        // color: #51506F;
        // font-size: 16px;
        // opacity: 0.6;
        // font-weight: normal;
        position: absolute;
        right: 20%;
        padding: 0;
        margin: 0;
        margin-left: -25px;
        opacity: 0.6;

        @media only screen and (min-width: 350px) {
            right: 17%;
        }

        @include media-breakpoint-up(md) {
            right: 13%;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    // height: 60px;
    .navbar-toggler {
        border: none;
        outline: none;
        box-shadow: none;
    }

    .navbar-toggler-icon {
        // modifying the stroke color from the bootstrap icon
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 95, 88)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h16M4 24h24'/%3E%3C/svg%3E");
    }

    .navbar-toggler[aria-expanded="true"]>.close {
        color: $red;
        display: inline;
        opacity: 1;
    }

    .navbar-toggler[aria-expanded="false"]>.close {
        display: none;
    }

    .navbar-toggler[aria-expanded="true"]>.navbar-toggler-icon {
        display: none;
    }

    &.scrolled {
        // background: $white;
        box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    }

    .navbar-brand {
        padding-top: 0;
        padding-left: 3px;

        img {
            height: 19px;
        }

        @include media-breakpoint-up(sm) {
            padding-left: 18px;
        }

        // @include media-breakpoint-up(md) {
        // 	padding-left: 18px;
        // }
        @include media-breakpoint-up(lg) {
            padding-left: 3px;
            margin-top: -5px;
        }
    }

    #navbarNavDropdown {
        width: 100%;
        // background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/jbpanda.png'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/bulb.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/cc.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/moneypot.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/red.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/stars.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/npug.png');
        // background-attachment: fixed;
        // background-position: 82% 15%, 7% 41%, 97% 35%, 1% 9%, 86% 67%, 36% 56%, 50% 30%;
        // background-repeat: no-repeat;
        // background-size: 4%, 4%, 4%, 4%, 2%, 4%, 5%;

        // @include media-breakpoint-up(sm) {
        //     background-position: 82% 15%, 7% 41%, 97% 35%, 1% 9%, 86% 67%, 36% 56%, 50% 20%;
        //     background-size: 3%, 3%, 3%, 3%, 1%, 3%, 4%;
        // }

        // @include media-breakpoint-down(md) {
        //     padding-bottom: 50px;
        // }

        .navbar-nav {
            width: 100%;
        }

        .dropdown-menu {
            background-color: #fff;
            z-index: 3000;
            border: none;
            border-radius: 12px;
            box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.17);
            padding: 10px 15px;

            .nav-link.a {
                color: $bluetext;
                line-height: 30px;
                opacity: 1;
                cursor: pointer;
                font-weight: 500;
                &:hover {
                	background-color: $blue;
                	border-radius: 8px;
                }
            }

            .nav-link.account {
                margin-top: 10px;
                border-top: 1px solid lighten(#51506F, 50%);

            }
        }

        .nav-item {
            // width: 90%;
            text-align: center;

            @include media-breakpoint-down(md) {
                padding-bottom: 30px;
            }

            @include media-breakpoint-up(lg) {
                width: 25%;

                &.searchLink {

                    img {
                        height: 100%;
                        padding: 8%;
                        cursor: pointer;
                    }
                }
            }

            a.nav-link {
                color: adjust-hue($bluetext, 20);
                font-family: $cat;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                position: relative;

                .mob {
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    left: -1px;
                }

                @include media-breakpoint-up(lg) {
                    // color: $grey;
                    font-size: 17px;
                    opacity: 0.6;
                    padding-left:0;
                    padding-right:0;
                }

                &.login {
                    color: $buttonblue;
                }

                // &.register {
                //     color: $red;
                //     font-size: 14px;
                //     font-weight: bold !important;
                //     font-family: $cat;
                //     text-align: center;
                //     // width: 120px;
                //     // height: 40px;
                //     padding: 10px;
                //     border-radius: 20px;
                //     background-color: $white;
                //     opacity: 1;
                //     // box-shadow: 6px 4px 14px 0 rgba(72,152,254,0.12);
                // }
            }
        }

        span {
            color: $grey;
        }
    }
}

