
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#wpPostEmbed.howitworks {
    p.step {
        color: $buttonblue;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 0;
    }

    h3 {
        font-family: $cat;
        color: $bluetext;
    }
    .icons {
    	
    	.c {
    		margin: 15px 0;
    	}
    	img {
    		margin-bottom:12px;
    	}
    	@include media-breakpoint-up(md) {
    		.c {
    			margin: 20px 0;
    		}
    	}
    	@include media-breakpoint-up(lg) {
    		margin: 0 -150px;
    		.c {
    			margin: 25px 0;
    		}
    	}
    }
}

.nav-tabs {
    li {
        width: 33.33%;
        text-align: center;
        vertical-align: middle;

        &.active {
            position: relative;
        }

        &.active:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $buttonblue;
            height: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

        }

        a {
            color: rgba(17, 12, 83, 0.45) !important;
        }

        a.cause:after {
            content: 'Organizers';

            @include media-breakpoint-up(sm) {
                content: 'Cause Organizers';
            }
        }

        a:hover {
            border: none;
            color: $textcolor !important;
        }

        a.active {
            color: $textcolor !important;
            font-weight: 600;
            border: none;
            background-color: #fff !important;


        }
    }


}

