
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#profile {
	a.secondaryButton {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
	}
}
