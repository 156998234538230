
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.contactus.container {
    width: 100%;
    text-align: center;
    max-width: 715px;
    p {
    	padding:5px 0;
    }
}

