
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#donationError,#donationErrorH {
    color: red;
    display: none;
    width: 100%;
    text-align: center;
    font-family: $open-sans;
    font-size: 14px;

    span {
        font-family: 'Times New Roman';
    }
}

#makeDonation {
    .modal-title {
        font-family: $cat;
        color: $bluetext;
    }

    .dollar {
        &::before {
            position: absolute;
            content: "$";
            color: $buttonblue;
            left: 20px;
            top: 18px;
        }
    }

    #other {
        width: 100%;
        height: 60px;
        background-color: $blue;
        border-radius: 14px;
        color: $buttonblue;
        text-align: right;
        padding-right: 15px;
        content: '.00';
        border: none;

        &::placeholder {
            color: $lightgrey;
        }

        &.highlight {
            // color:$red;
            font-weight: 600;
            border: 1px solid $buttonblue;

        }

    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            border: 1px solid $formgrey;
            border-radius: 30px;
            background-color: #fff;
            margin-left: 1%;
            margin-right: 1%;
            color: $grey;
            &.highlight {
                border: 1px solid $buttonblue;
                color:$buttonblue;
            }
        }
    }
}

