
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.assignrecipient {
	.rec{
		width: 100%;
		padding:30px;
		text-align: center;
		color:$bluetext;
		font-family: $cat;
		font-size: 18px;
		&.complete {
			text-align: left;
			padding-left: 30px;
		}
		div {
			font-size:18px;
			font-family: $cat;
			display: inline-block;
			&.t {
				width:150px;

			}
		}
	}
	.sentToRecipient {
		padding:20px;
		background-color: $ctabg;
		color:#fff;
		font-family: $cat;
		font-size: 18px;
	}
}

