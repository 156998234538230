// Body
$body-bg: #f8fafc;
// Typography
$cat: 'Catamaran', sans-serif;
$open-sans: 'Open Sans', sans-serif;
// Colors
$white: #fff;

$light: #fff;
$logoColor: #1F78EB;
$greybg : #EDECF3;
$blue: #E9F3FF;
$messageblue: #f1f7ff;

$bluetext: #120C53;
$buttonblue: #4898FF;
$textcolor: #52506F;
$lightgrey: #B1B0BE;
$formgrey: #E8EBF0;
$actiongrey: rgba(177, 176, 190, 0.6);
$icongrey: #DEE3F3;
$dategrey: #C1C8D9;
$red: #FF5F58;
$green: #86af49;
$brightgreen: #7ED229;
$grey: #51506F;
$boxshade: rgba(17,12,83,0.08);
$ctabg: #110c53;