
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#newCause {
    max-width: 750px;

    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        padding-left: 15px;
    }

    h4 {
        color: $textcolor;
        font-family: $open-sans;
        font-weight: normal;
        font-size: 16px;
        padding-bottom: 1rem;
        padding-left: 15px;
    }

    //   a.next {
    //       border-radius: 30px;
    //       width:100%;
    //       height: 50px;
    //       border: none;
    //       // margin-left: 2%;
    //       // margin-right: 2%;
    // font-family: $cat;
    // font-weight: 600;
    // font-size: 15px;
    // line-height: 40px;
    // letter-spacing: 0;
    //       background-color: $red;
    //       color: $white;

    //   }
}

