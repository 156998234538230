
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#landing {
    max-width: 100%;
    // margin-top: 40px;

    section {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .l {
            min-width: 35%;

            .contain>div {
                text-align: center;
                padding: 20px;

            }
        }

        .r {
            min-width: 35%;
        }

        .form-group {

            padding: 20px;

            a {
                border-radius: 10px;
            }

        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding: 20px;

            >div {
                // width: 50%;
            }

            h1 {
                font-size: 50px;
                line-height: 50px;
            }
        }
    }

    section.one {
        >div {
            width: auto;
        }

        p {
            padding-top: 5px;

            @include media-breakpoint-up(md) {
                padding-top: 15px;
            }
        }
    }

    section.two { 
        border-radius: 4px;
        min-height: 190px;

        >div {
            width: 100%;
            text-align: center;
            padding: 0 8px;
            background-color: $blue;
            // padding:10% 20%;
            >h2,
            p {
                // color: #fff;
            }

            >h2 {
                font-size: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }
            }

            >p {
                font-weight: 600;
                text-align: center;

            }
        }
    }

    section.three,
    section.five {
        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }

    section.six {
        >div {
            width: 100%;
            padding: 25px 5px;
            background-color: $blue;
            border-radius: 15px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }
        p:nth-child(3) {
        	font-weight: 600;
        }
    }

    .contain {
        // padding: 5% 10%;
        padding-bottom: 10px;
    }

    .imgContain {
        width: 100%;
        padding-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }

    }
}

