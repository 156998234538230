
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#different {
    h2 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    .diffItem {
        flex-direction: column;
        text-align: center;

        h3 {
            color: $bluetext;
            font-family: $cat;
            font-weight: 600;
            font-size: 18px;
        }
    }

    p {
        font-family: $open-sans;
        font-size: 16px;
        font-weight: 500;
        color: $textcolor;
    }
}

