
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#ctCarousel {
    margin-top: 50px;
}

#hSlider {
    padding-bottom: 20px;
    .d-block {
    	display: flex !important;
    	flex-direction: column;

    	@include media-breakpoint-up(md) {
    		flex-direction: row;
    		align-items: center;
    	}
    }
    .triple {

        text-align: center;
        margin: 22px -30px;
        margin-top: 0;
        .contain {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 6%;
            @include media-breakpoint-up(sm) {
            	column-gap: 10%;
            }
            @include media-breakpoint-up(md) {
            	column-gap: 15%;
            }
            >div {
                display: flex;
                flex-direction: column;
                padding-top: 23px;
                @include media-breakpoint-up(lg) {
                	 padding-top: 5px;
                	}
            }
        }

        .el0 {

            margin: 1% 3px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            >div {
                &.im {
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                    border: 1px solid lighten($lightgrey,10%);
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                }

                color: $lightgrey;
                font-weight: 600;
                font-size: 17px;

                &.l {
                    padding-top: 15px;
                    color: darken($lightgrey,10%);
                    font-family: $cat;
                    font-size: 14px;
                     @include media-breakpoint-up(sm) {
                     	font-size: 16px;
                     }
                     @include media-breakpoint-up(lg) {
                     	font-size: 18px;
                     }
                }
            }



            &.active {
                .im {
                    background-color: #4898FF;
                    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
                    border: none;
                }

                >div {
                    color: #fff;

                    &.l {
                        color: $buttonblue;
                    }
                }
            }
        }
    }
}

#hSlider .carousel-inner {
    div.a {
        @include media-breakpoint-up(md) {
            float: left;
            width: 50%;
        }
    }

    h3 {
        color: $bluetext;
        font-family: $cat;
        font-size: 30px;
        font-weight: 700;
        padding-left: 15px;
        line-height: 38px;
        text-align: center;
        @include media-breakpoint-up(md) {
        	text-align: left;
        }
    }

    p,
    ul li {
        color: $textcolor;
        font-family: $open-sans;
        font-size: 16px;
        line-height: 26px;
        padding: 5px;
        font-weight: 400;

        a {
            color: $buttonblue;
            cursor: pointer;
        }

        @include media-breakpoint-up(md) {
            padding: 15px;
        }
    }

    ul li {
        padding: 2px 5px;
    }

    .cta {
        width: 100%;

        // float: left;
        @include media-breakpoint-up(md) {
            padding: 15px;
        }

        .btn {
            color: #fff;
            background-color: $buttonblue;
            border-radius: 25px;
            padding: 15px 35px;
            font-size: 15px;
            font-family: $cat;
            font-weight: 600;
            line-height: 18px;
        }

    }

    .visualsContain {
        padding-top: 20px;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(sm) {
            padding: 20px;
        }

        img {
            width: 100%;
            max-width: 410px;

            &.items {
                max-width: 506px;
            }
        }
    }

    .visuals {
        position: absolute;
        width: 95%;
        height: 95%;
        top: 5%;
        background-color: $blue;

        &.pink {
            background-color: rgba(255, 95, 88, 0.08);

        }

        width: 100%;
        border-radius: 15px;
        padding-top: 250px;

        h3 {
            color: $bluetext;
            font-family: $cat;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            text-align: left;
            padding: 15px 10px 5px 10px;
            margin-bottom: 0;
        }

        p {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
            padding: 10px;
        }

    }

}

