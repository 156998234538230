
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#donateCheckout {
    .title {
        width: 100%;
        color: $bluetext;
        font-family: $cat;
    }

    #payment-form {
        .form-row {
            padding: 10px 0;
        }
    }

    button.btn {
        border-radius: 30px;
        width: 96%;
        height: 50px;
        margin: 2%;
        background-color: $red;
        color: #fff;
    }
}

