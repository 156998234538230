
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.howToStart {
	.secondaryButton {
		width: 180px;
		display: block;
		border: 1px solid $buttonblue;
		
	}
	
	img {
    		margin-bottom:12px;
    	}
	
}

