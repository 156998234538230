
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#accountConversation #cannedMessages {
	background-color:#fff;
	flex-direction: row;
	justify-content: center;
	.canned {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		max-width: 200px;
		border: 1px solid $formgrey;
		border-radius: 10px;
		background-color: #fff;
		color:$textcolor;
		margin:10px 5px;
		padding:10px;
		cursor:pointer;
		font-family: $cat;
		&:hover {
			border-color: $grey;;
		}
	}
}
