
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.causeActions {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1090;
    background: $white;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
	 @include media-breakpoint-up(sm) {
	 	display: none;
	 }
    .shareItem,
    .likeIt {
        width: 18%;
        color: $lightgrey;
    }

    i.fas {
        color: $red;
    }

    i.far {
        color: $lightgrey;
    }

    i.fa-comments-o {
        color: $lightgrey;
    }

    button {
        border-radius: 30px;
        width: 46%;
        height: 50px;
        margin-left: 2%;
        margin-right: 2%;
        outline: none;


        &.support {
            background-color: $red;
            color: $white;
            border:none;
            font-family: $cat;
            &.mShare {
            	border: 1px solid #51506F;
			    border-radius: 25px;
			    background-color: #fff;
			    color: $grey;
		
            }
            &:hover {
	        	background-color: #fff;
	        	border: 1px solid $red;
	        	color: $red;
	        }
        }

        &.browse {
            border: 1px solid $actiongrey;
            background-color: $white;
        }
    }
}

@keyframes heart {
    from {
        color: $lightgrey;
    }

    to {
        color: $red;
        
    }
}

