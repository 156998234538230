html,
body {
    min-height: 100vh;
    background-color: $blue;
    // padding-bottom: 40px;
}

h1 {
    font-size: 24px;
    color: $bluetext;
    text-align: left;
    line-height: 34px;
    font-weight: 500;
    padding: 1rem 0;
    padding-bottom: 0;
    margin-bottom: 5px;
    background: transparent;
    max-width: 800px;
}

strong {
    font-weight: 700;
}

a {
    color: $bluetext;

    &.textLink {
        color: lighten($bluetext, 20%);
    }
}

.userIdenticon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 5px;

    &.messages {
        width: 45px;
        height: 45px;
    }
}

.ship {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 29px;
    border-radius: 12px;
    z-index: 1040;

    .shippable {
        visibility: hidden;
        width: 160px;
        background-color: #fff;
        color: $bluetext;

        border-radius: 0px 12px 12px 0;
        height: 100%;
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $cat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        position: absolute;
        top: 0;
        left: 30px;
        z-index: 2000;
    }

    &:hover {
        .shippable {
            visibility: visible;
        }
    }
}

#app {
    height: 100%;
    width: 100%;
    padding-top: 56px;
    background-color: #fff;
    overflow: hidden;
}

.mainContain {
    min-height: 100vh;
    position: relative;
    background-color: $white;
    overflow: inherit;
    padding-bottom: 60px;

    h1 {
        font-size: 24px;
        color: #120C53;

        line-height: 34px;
        font-weight: 500;
        padding: 1rem 0;
        padding-bottom: 0;
        margin-bottom: 5px;
        background: transparent;
        max-width: 800px;
    }

    h2.title {
        font-family: $cat;
        color: $bluetext;
    }

    .centered {
        padding-top: 20px;

        h2.title {
            text-align: center;
            margin-bottom: 0;
        }

        >p {
            padding: 10px;
            text-align: center;

            @include media-breakpoint-up(lg) {
                padding: 10px 15%;
            }
        }
    }

    h4 {
        font-family: $open-sans;
        // font-weight:400;
        color: $bluetext;
        font-size: 16px;
    }

    h5 {
        color: $bluetext;
    }

    p,
    label {
        font-family: $open-sans;
        color: $textcolor;
        font-size: 16px;
    }
}

.alert-success {
    display: none;
}

.alert-processing {
    position: absolute;
    height: 140px;
    top: 15%;
    left: 50%;
    background: #fff;
    border: #ccc;
    border-radius: 3px;
    width: 300px;
    margin-left: -150px;
    z-index: 2000;
    font-size: 18px;

    a {
        cursor: pointer;
    }
}

.flashMessage {
    position: fixed;
    top: 40%;
    width: 70%;
    width: 350px;
    z-index: 2000;
    left: 50%;
    margin-left: -175px;
    color: $bluetext;
    border-radius: 31px;
    background-color: #FFF;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    border: none;
    text-align: center;

    &.alert-danger {
        color: $red;
    }
}

.container h2 {
    color: $bluetext;
    font-family: $cat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    padding: 10px 0;
}

// Account container shared
.accountContainer .row {

    h2.title,
    h3 {
        color: $bluetext;
        width: 100%;
        font-family: $cat;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        padding: 10px 25px;
    }

    h3 {
        font-size: 20px;
        text-align: left;
        padding: 5px 0;

    }

    p {
        font-family: $open-sans;
        color: $textcolor;
        font-size: 16px;
    }

    div.empty {
        display: flex;
        flex-direction: column;
        padding-top: 20%;
        align-items: center;
        justify-content: center;

        img {
            width: 100px;
            height: auto;
        }

        p.nothing {
            padding: 0 25%;
            color: #A9A7B4;
            font-family: $open-sans;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
        }
    }

}

////////// // Loading css
.vld-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    display: none;
    justify-content: center;
    overflow: hidden;
    z-index: 1100
}

.vld-overlay.is-active {
    display: flex
}

.vld-overlay.is-full-page {
    z-index: 1500;
    position: fixed
}

.vld-overlay .vld-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: $blue;
    opacity: 0.5
}

.vld-overlay .vld-icon,
.vld-parent {
    position: relative
}

// //// End loading css
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1900; //Higher than shipping icons

    &.initial {
        display: block;
        background-color: #fff;
        margin:0 auto;
        text-align: center;
        padding-top:25%;
    }

}

.fa-twitter {
    color: #00acee;
}

.fa-facebook {
    color: #3b5998;
}

.fa-envelope {
    color: $formgrey;
}

.fa-whatsapp {
    color: #25d366;
}

.fa-instagram {
    color: #e95950;
}

#shareModal {
    .modal-header {
        padding: 0;
        border-bottom: 0;

        button {
            span {
                margin-right: 3px;
            }
        }
    }

    .modal-body {
        padding-top: 0;

        .itemShare {
            padding-top: 0;
        }
    }
}

.fadeout {
    position: absolute;
    padding: 0;
    bottom: 0em;
    width: 100%;
    height: 5em;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%);
}

// all tables should use this
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    thead {
        tr {
            background-color: rgba(194, 200, 216, 0.1);

            th {
                padding-left: 10px;
                color: $lightgrey;
                text-transform: uppercase;
                font-family: $open-sans;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 30px;

                div {
                    display: inline-block;
                    padding: 0px 5px;
                    text-transform: lowercase;
                    border-radius: 8px;
                    background-color: $formgrey;
                    line-height: 11px;
                    margin-left: 3px;
                    cursor: help;
                }
            }
        }
    }

    tbody {
        tr {

            td {
                padding-left: 10px;
                color: $bluetext;
                font-family: $open-sans;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 30px;
            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.67;
}

#upload-label {
    color: $lightgrey;
}

.container.textonly {
    max-width: 600px;
}

a.secondaryButton {
    border-radius: 30px;
    background-color: $blue;
    padding: 4px 12px;
    font-family: $cat;
    font-weight: normal;
    box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.12);
    font-size: 16px;
    color: $buttonblue;
    text-align: center;
    cursor: pointer;
    text-decoration: none;

    &.preview {
        width: 100%;
        background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/icons/show.svg');
        background-repeat: no-repeat;
        background-position: 17px 14px;
        background-size: 19px;
    }

    &.rev {
        background-color: $buttonblue;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $buttonblue
        }
    }

    &:hover {
        background-color: $buttonblue;
        color: #fff;
        text-decoration: none;
    }
}
