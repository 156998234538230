
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';


body.homeOnly {
    background-color: #fff;

    .homePageHeader {
        display: flex;
    }

    #app {
        padding: 0;
        width: 100%;
    }

    .mainContain {
        background: transparent;
        // background-color: transparent;
        padding-left: 15px;
        padding-right: 15px;
    }
}

#homePage {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;



    h2 {
        width: 100%;
    }

    h3 {
        color: $bluetext;
        font-family: $cat;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2rem;
        text-align: center;
    }

    .slider {
        background-color: #fff;

        h3 {
            font-size: 30px;
        }
    }

    .diff {
        background-color: #fff;
    }

    .popularCauses {
        background-color: #fff;
        padding-top: 20px;

        a.secondaryButton {
            display: flex;
            position: relative;
            border: 1px solid $buttonblue;
            // border-radius: 25px;
            left: 50%;
            margin-left: -25%;
            width: 50%;
            height: 40px;
            justify-content: center;
            align-items: center;
            font-size: 19px;

            &:hover {
                border: 1px solid rgba(177, 176, 190, 0.4);
            }

        }
    }

    div[class^='section'] {
        background-color: #fff;
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-of-type(odd) {
            flex-direction: column-reverse;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;

            &:nth-of-type(odd) {
                flex-direction: row;
            }

            padding-top: 50px;

            :last-child.slot-content {
                padding-left: 25px;
            }
        }

        .slot-content,
        .image {
            padding-top: 15px;

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }
        }

        .image {
            width: 100%;

            @include media-breakpoint-up(md) {
                flex-basis: 130%;
            }

            text-align: center;

            img {
                width: 90%;
            }
        }

        a.more {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            width: 120px;
            height: 40px;

        }
    }

    @include media-breakpoint-up(md) {
        .section-2 .image {
            width: 120%;
        }

        .section-3 div.image,
        .section-4 div.image {
            flex-basis: 90%;
        }
    }

    .getStarted {
        background-color: #fff;
        padding: 30px 15px;

        .startContainer {
            width: 100%;
            height: 100%;
            background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/home/cta.svg');
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 14px;
            padding: 50px 25px;

            p {
                a {
                    color: #fff;
                }
            }
        }

        h2 {
            color: $white;
        }

        p {
            color: #B8B5E0;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
        }

        a.btn {
            display: block;
            position: relative;
            border-radius: 25px;
            left: 44%;
            margin-left: -22%;
            width: 56%;
            color: $white;
            background-color: $buttonblue;

            @include media-breakpoint-up(sm) {
                max-width: 300px;
                left: 50%;
                margin-left: -150px;
            }
        }
    }

    .recentItems {
        // border-top: 20px solid $blue;
        padding: 15px;
        background-color: #fff;
    }

    .itemCats {
        background-color: #fff;
        padding-top: 15px;

        .row {
            padding: 10px;
        }

        .col-sm-4 {
            margin-bottom: 10px;
            border-radius: 9px;
            height: 60px;
            font-size: 18px;
            line-height: 30px;
            color: $bluetext;
            font-family: $cat;
            padding: 0 15px;

            div {
                display: flex;
                align-items: center;
                padding-left: 10px;
                width: 100%;
                height: 100%;
                border-radius: 9px;

                background-color: rgba(213, 231, 255, 0.5);


            }

            &:nth-of-type(even) {
                div {

                    background-color: rgba(255, 95, 88, 0.1);
                }

            }

            a {
                color: $bluetext;
                font-family: $cat;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 30px;
                margin-left: -10px;
            }

            img {

                padding-left: 15px;
                padding-right: 21px;
            }
        }
    }
}

