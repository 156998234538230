
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#charities {
    max-width: 100%;
    // margin-top: 40px;
    .btn-primary, .secondaryButton {
    	font-size: 1.1em;
    	padding: 12px 25px;
    }
    section {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .l {
            min-width: 35%;

            .contain {
            	 padding: 40px; 
            >div {
                text-align: center;
               

            }
             
        }
        }

        .r {
            min-width: 35%;
        }

        .form-group {

            padding: 20px;

            a {
                border-radius: 10px;
            }

        }
        
        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding: 20px;

            >div {
                // width: 50%;
            }

            h1 {
                font-size: 50px;
                line-height: 65px;
            }
        }
    }

    section.one {
        .imgContain {
            box-shadow: none;
            min-width: 38%;
        }

        >div {
            width: auto;
        }

        p {
            padding-top: 5px;
            padding-bottom:30px;
            font-size: 18px;
            @include media-breakpoint-up(md) {
                padding-top: 15px;
            }
        }
    }

    section.two {

        background-color: #FBFBFF;
        border-radius: 4px;
        min-height: 190px;
        img {
        	padding-bottom:30px;
        	padding-top: 10px;
        }
        @include media-breakpoint-up(md) {
            min-height: 390px;
        }

        >div {
            width: 100%;
            text-align: center;
            padding: 0 8px;

            // padding:10% 20%;
            >h2,
            p {
                // color: #fff;
            }

            >h2 {
                font-size: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }
            }

            >p {
                font-weight: 600;
                text-align: center;
                font-size: 17px;
            }
        }
    }

    section.three,
    section.five {
        .r {
            position: relative;
            z-index: 1;

            .blurred {
                background: #59B1E6;
                opacity: 0.8;
                filter: blur(150px);
                position: absolute;
                width: 50%;
                height: 50%;
                right: 0;
                top: 20%;
                z-index: 2;
            }

            .imgContain {
                position: relative;
                // left: 0;
                //      top: 0;
                z-index: 3;
                border-radius: 24px;
                background: #fff;
            }

        }


        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }
    section.four {
    	.l {
            position: relative;
            z-index: 1;

            .blurred {
                background: #59B1E6;
                opacity: 0.8;
                filter: blur(150px);
                position: absolute;
                width: 50%;
                height: 50%;
                left: 0;
                top: 20%;
                z-index: 2;
            }

            .imgContain {
                position: relative;
                // left: 0;
                //      top: 0;
                z-index: 3;
                border-radius: 24px;
                background: #fff;
            }

        }
        .r {
        	div.contain {
            	padding: 40px;
            }
        }

    }
    section.six {
        >div {
            width: 100%;
            padding: 25px 5px;
            background-color: #FBFBFF;
            border-radius: 15px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }

        p:nth-child(3) {
            font-weight: 600;
        }
    }

    .contain {
        // padding: 5% 10%;
        padding-bottom: 10px;
    }

    .imgContain {
        width: 100%;
        padding-bottom: 10px;
        z-index: 500;
        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
        border-radius: 26px;

        img {
            width: 100%;
            height: auto;
            padding: 24px;
        }

    }
    section.bubbles {
    	p::before {
        		width: 12px;
        		height: 12px;
        		display: inline-block;
        		border-radius: 4px;
        		
        		content:' ';
        		padding:3px;
        		color:#F15A2B;
        		margin-right:12px;
        	}
    	p:nth-child(2)::before {
    		background: #F0BB1F;
    	}
    	p:nth-child(3)::before {
    		background: #F15A2B;
    	}

    	p:nth-child(4)::before {
    		background: #006380;
    	}
        
    }
    .centered {
    	text-align: center;
    	width: 100%;
    	padding: 10px;
    	.rev {
    		padding:10px 20px;
    		border-radius: 10px;
    	}
    }
}

