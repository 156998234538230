
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.payment-page {
    max-width: 550px;
	@include media-breakpoint-up(sm) {
		padding-top:30px;
	}
	.borderError {
		margin-top:40px;
		border: $red solid 2px;
		padding:10px;
		border-radius: 8px;

	}
    .row>.details {
        padding: 0 15px;
        margin: 8px 15px;

        @include media-breakpoint-up(sm) {
            margin: 0;
          
        }

        border: 1px solid $formgrey;
        border-radius: 10px;

        .item {
            &.detail {
                height: 100px;
            }

            padding: 10px 0;
            position: relative;

            &:after {
                position: absolute;
                content: '';
                width: 92%;
                left: 4%;
                border-bottom: 1px solid $formgrey;
                bottom: 0;
            }

            &.total:after {
                border-bottom: none;
            }

            .col-5 {
                // margin-left:10px;
                height: 100%;

                .itemImage {
                    height: 80px;
                    background-position: center;
                    background-size: cover;
                    border-radius: 8px;
                }
            }

            .col-7 {
                height: 100%;
                padding: 5px 0;

                // display: flex;
                // align-items: center;
                // justify-content: center;
                // margin-right:10px;
                >div {
                    font-size: 16px;
                    font-family: $cat;
                    color: $grey;
                    line-height: 24px;
                    width: 100%;
                }

                >div.cause {
                    font-size: 14px;
                    font-family: $open-sans;
                    color: $lightgrey;
                    line-height: 20px;
                }

            }

            .col-6 {
                &.keys {
                    // display: flex;
                    align-items: center;
                    
                    >div {
                        color: #B8B8BF;
                        font-size: 12px;
                        line-height: 30px;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-family: $cat;
                        padding-top: 4px;
                        width: 100%;
                        position: relative;
                        .tax {
                        	left:105px;
                        }
                    }
                }

                &.vals {
                    align-items: center;

                    >div {
                        color: $bluetext;
                        font-size: 15px;
                        line-height: 30px;
                        font-family: $open-sans;
                        padding-top: 4px;
                        width: 100%;
                        text-align: right;
                    }
                }
            }
        }

        .total .col-6.vals,
        .total .col-6.keys>div {
            font-weight: 600;
            color: $bluetext;
        }
    }

    .row>.cardInfo {
        padding: 0 15px;
        // margin: 8px 15px;
    }
}

