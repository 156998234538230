
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.causeChoice {
	p {
		padding-left:15px;
	}
	.ui-menu-item > div {
		padding:5px;
	}
}
#choice {
	font-size:14px;
	margin-bottom:20px;
	background-size: 45px;
	background-repeat: no-repeat;
	background-position: right 5px center;
	@media only screen and (min-width: 375px) {
		background-position: right 15px center;
		background-size: 65px;
	}


}
