
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.misusageReported {
	padding:50px 25%;
	color: $bluetext;
	h2.title {
		font-size: 22px;
		font-family: $cat;
	}
	p {
		font-size: 18px;
		font-family: $open-sans;
		color: $textcolor;
	}
}
