
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.giftGallery {
    overflow-x: hidden;
    width: 100%;
    position: relative;

    // padding-left:0;
    // padding-right:0;


    .ship {
        top: 20px;

        @include media-breakpoint-up(md) {
            top: 30px;
        }
    }

    .mainOne {
        position: relative;
        height: 239px;
        width: auto;
        text-align: center;
        border-radius: 10px;
        padding-top: 10px;
        -ms-scroll-chaining: none;

        @include media-breakpoint-up(sm) {
            height: 255px;
        }

        @include media-breakpoint-up(md) {
            padding-top: 20px;
            height: 440px;
        }

        img {
            height: 100%;
            max-width: auto;
            user-select: none;
            pointer-events: none;
            border-radius: 10px;
        }
    }

    .thumbs {
        ul {
            padding-top: 15px;
            white-space: nowrap;
            overflow-x: scroll;
            padding-left: 0;

            li {
                display: inline-block;
                width: 138px;
                height: 70px;
                background-size: cover;
                border-radius: 8px;
                cursor: pointer;
                margin-right: 14px;
            }
        }
    }
}

