
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#locationStep {
	max-width:750px;
    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;

    }

    h4 {
        color: $textcolor;
        // font-family: $open-sans;
        font-weight: normal;
        font-size: 15px;
        padding-bottom: 1rem;
        padding-left: 15px;
    }

    #copyUrl {
        border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        outline: none;
        color: $buttonblue;
    }

    .sharingLinks,
    .directLink {
        width: 100%;
        text-align: center;
        input {
			padding-left: 10px;
		}
        a.secondaryButton {
            width: 94%;
            display: inline-block;
            // padding: 10px;
            // text-align: center;
            // color: $buttonblue;
            // background-color: $blue;
            // border-radius: 25px;
            margin: 10px;
            max-width: 400px;
        }
        div {
            width: 100%;
            margin-bottom: 10px;
            border: 1px solid rgba(177, 176, 190, 0.2);
            border-radius: 25px;
            padding: 16px 0;
            text-align: left;

            span {
                color: $grey;
                font-family: $cat;
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 18px;
                cursor:pointer;
            }

            i {
                padding: 0 10px;
                width: 30px;
                text-align: center;
                margin: 0 6px;
            }
        }
    }

    p {
        text-align: left;
        color: $textcolor;
        font-family: $open-sans;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
        padding-left: 15px;
        width: 100%;
        // @include media-breakpoint-up(sm) {
        // 	width: auto;
        // }
    }
    .publish {
    	flex-direction: column;
    	align-items: center;
    	.secondaryButton, .btn-primary {
    		max-width: 300px;
    		margin-bottom:30px;
    		height:50px;
    		padding:12px;
    	}
    	input {
    		min-width:300px;
    	}
    }
}

