
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#supporting {
    margin: 20px 0;
    padding-bottom: 15px;
    cursor: pointer;
    // @include media-breakpoint-up(sm) {
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    // }
	position: relative;
    h3 {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        padding: 15px;
        padding-left:10px;
    }

    .name {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left:10px;
        h4 {
            color: $bluetext;
            font-family: $cat;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;

            display: inline-block;
            overflow: hidden;
            width: 70%;
        }

        img {
            height: auto;
            width: 30%;
            border-radius: 10px;
            display: inline-block;
        }
    }

    .desc {
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 100px;
        font-family: $open-sans;
        padding: 8px 0;
        padding-left: 10px;
    }
}

