
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.causeOrders {
	table, h2.title{
		padding-left:15px;
	}
	tr.totals {
		td {
			font-weight: 600;
		}
	}
	span.y {
		&:before {
                content: "\2713";
                display: inline-block;
                color: #86af49;
                padding: 0 3px 0 0px;
            }
	}
	.export {
		padding-left:25px;
		padding-top: 10px;
		>.form-group {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
			align-items: center;
			@include media-breakpoint-up(sm) {
				flex-direction: row;
				column-gap: 5px;
				align-items: normal;
			}
		}
		select,.secondaryButton{
			width:170px;
			height: 35px;
			padding: 6px 12px;
		}
		
	}
}
