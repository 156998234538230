
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.notifications,
#causesSharedWith {
    .row h2.title {
        padding-left: 20px;
    }

    .switch {
        position: relative;
        display: table-cell;
        width: 42px;
        height: 22px;
        margin-bottom: 25px;
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #F0F2F6;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 2px;
        background-color: #BFC5CF;
        // border: 0.2px solid #BEC5CF;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: rgba(72, 152, 255, 0.12);
    }

    input:focus+.slider {
        box-shadow: 0 0 1px rgba(72, 152, 255, 0.12);
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
        background-color: #4898FF;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider-label {
        display: table-cell;
        vertical-align: middle;
        padding-right: 5px;
        padding-left: 20px;
        width: 80%;
        color: $bluetext;
        font-family: $open-sans;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 17px;
        }

        span {
            display: block;
            width: 100%;
            font-size: 11px;
            color: $grey;

            @include media-breakpoint-up(sm) {
                font-size: 12px;
            }
        }
    }
}

