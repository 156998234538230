
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#wpPostEmbed {
    margin: 0 auto;
    width: 100%;
    max-width: 650px;

    &.page {

        h1,
        h2 {
            padding-top: 20px;
            font-size: 22px;
            color: $bluetext;
            font-family: $cat;
            font-weight: 500;
        }

        img {
            max-width: 100%;
            height: auto;
            border-radius: 3px;
        }

        h1 {
            font-size: 28px;
        }

        h2 {
            color: $bluetext;
            font-family: $open-sans;
        }
        h3 {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 22px;
        }
        p,
        li {
            font-family: $open-sans;
            color: $textcolor;
            font-size: 16px;
            padding-bottom: 4px;

            a {
                color: $buttonblue;
                &.rev {
                	color: #fff;
                	&:hover{
                		color: $buttonblue;
                	}
                }
            }
        }
    }
    a.more {
    	// background-color: $buttonblue;
     //    color: #FFFFFF;
     //    font-family: $cat;
     //    font-size: 15px;
        display:flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 25px;
        height:40px;
        cursor:pointer;
        margin-bottom: 5px;
    }
    a.anchor {
    	color: $buttonblue;
    	text-decoration: none;
    	padding-bottom:5px;
    	display: block;
    }
}

