
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.eula {
    max-width: 650px;

    #wpPostEmbed {
        max-height: 350px;
        overflow-y:scroll !important;
        ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
overflow-x: hidden;
        @media (min-height: 600px) {
        	max-height: 480px;
        }
        @media (min-height: 700px) {
        	max-height: 520px;
        }
        @include media-breakpoint-up(sm) {
            max-height: 550px;
        }
        @media (min-height: 1000px) {
        	max-height: 720px;
        }
        @media (min-height: 1200px) {
        	max-height: 950px;
        }
        padding:5px;
        background-color: lighten($lightgrey, 25%);
        margin-top: 10px;
    }

    .bottom {
        text-align: center;
        width: 100%;
    }

    .agree {
        display: block;
        margin: 10px auto;
        width: 100%;
        max-width: 650px;

        // this should be refactored as there a few buttons like this
        .btn {
            background-color: #4898FF;
            color: #FFFFFF;
            font-family: "Catamaran", sans-serif;
            font-size: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 25px;
            &.disabled {
            	background-color: $lightgrey;
            	color: #FFFFFF;
            }
        }
    }
}

