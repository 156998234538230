
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.myCauseItem {
    // width: 100%;
    max-width: 339px;
    margin: 10px;
    padding: 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    margin-bottom: 12px;
    border-radius: 0px 0px 10px 10px;

    .causeImageContainer {
        width: 100%;
        max-width: 339px;
        padding: 0;
        position: relative;

        img {
            width: 100%;
            border-radius: 10px 10px 0px 0px;
        }

        .shaded {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 10px 10px 0px 0px;
            background-color: rgba(46, 46, 48, 0.55);

            .name {
                position: absolute;
                top: 40%;
                left: 20px;
                color: #fff;
                font-family: $cat;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 24px;
                max-width: 60%;
            }

            .published {
                position: absolute;
                bottom: 10%;
                left: 20px;
                font-family: $open-sans;
                color: #fff;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-transform: uppercase;
                padding: 3px 20px;
                border-radius: 14px;
                background-color: $red;
                cursor: pointer;
                z-index: 500;

                &.recipient {
                    right: 20px;
                    left: unset;
                    background-color: $red;

                    &.recipientSet {
                        background-color: $brightgreen;
                    }
                }

                &.active {
                    background-color: $brightgreen;
                }
            }

        }

    }

    .details {
        padding-top: 10px;

        .key,
        .value {
            width: 49%;
            display: inline-block;
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 30px;
            padding-bottom: 12px;
        }

        .key {
            text-align: left;
        }

        .value {
            text-align: right;
        }

        .more {
            border: 1px solid rgba(177, 176, 190, 0.4);
            border-radius: 25px;
            width: 100%;
            text-align: center;

            padding: 10px 0;
            font-family: $cat;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 12px;

            &.ed {
                a {
                    padding: 0 20px;

                    &.del {
                        color: $red;
                    }
                }
            }

            a {
                color: $grey;
                cursor: pointer;
            }
        }

    }
}

