
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#accountConversations {
    i.fa-refresh {
        margin-left: 15px;
        color: $buttonblue;
        font-size: 16px;
        cursor: pointer;
    }

    >.container {
        padding-bottom: 6px;

        @include media-breakpoint-up(sm) {
            padding-bottom: 10px;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 12px;
        }
    }

    .title {
        font-size: 24px;
        font-family: $cat;
        line-height: 30px;
        color: $bluetext;
        padding: 15px;
    }

    .row {
        width: 100%;
    }

    .conversations {
        &.self {
            background-color: darken(white, 1%);

            i {
                font-size: 10px;
                color: $buttonblue;
            }

            &.unread {
                background-color: darken(white, 1%);

                i {
                    font-size: 10px;
                    color: $formgrey;
                }
            }
        }

        &.other {
            // background-color: #fff;
            background-color: darken(white, 1%);

            i {

                display: none;
            }

            &.unread {
                background-color: $messageblue;

                i {
                    display: none;
                }
            }
        }

        background-color: #fff;
        // opacity:0.6;
        width: 100%;
        border-radius: 12px;
        height: 80px;
        margin-bottom: 5px;
        cursor: pointer;

        .avatar {
            height: 100%;
            width: 20%;
            max-width: 100px;
            position: relative;
            // display: flex;
            // flex-direction: column;
            float: left;
            // margin-left: 2%;
            background-size: 180%;
            background-position: 0 50%;
            background-repeat: no-repeat;
            padding-top: 10%;
            border-radius: 12px;

            @include media-breakpoint-up(md) {
                background-size: 100%;
            }

            img {
                margin: 0;
                width: 36px;
                height: 36px;
                position: absolute;
                bottom: 0px;
                right: -10%;
                border: 2px solid $messageblue;
                background-color: $messageblue;
            }

        }

        .center {
            width: 60%;
            float: left;
            padding: 10px;
            height: 100%;

            .userName {
                color: $bluetext;
                font-size: 15px;
                line-height: 26px;
                font-family: $open-sans;
                font-weight: 600;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .lastMessage {
                color: $textcolor;
                font-size: 13px;
                line-height: 26px;
                font-family: $open-sans;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .info {
            width: 18%;
            float: left;
            height: 100%;

            .date {
                width: 100%;
                height: 50%;
                color: $dategrey;
                font-size: 11px;
                line-height: 18px;
                padding-top: 15px;
                padding-right: 10px;
                text-align: right;
            }

            .star {
                width: 100%;
                height: 50%;
                text-align: right;
                padding-right: 10px;
            }
        }
    }
}

