
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#reportFlag {
    width: 100%;
    text-align: center;
    padding-top: 20px;

    a {
        padding: 2px;
        color: #B8B8BF !important;
        text-transform: uppercase;
        cursor: pointer;
        font-family: $cat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;

        span {
            margin-left: 7px;
        }
    }
}
.ct.forms #reportFlag {
    .reportBox {
        display: none;
        textarea {
        min-height: 75px;
        width: 100%;
        border-radius: 10px;
    }
    }
}

