
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#addGift,
#editGift {
    max-width: 750px;

    .uploaded {
        width: 100%;
    }

    .done {
        color: $green;
    }

    p {
        width: 100%;
    }
    span.dollar {
    	position:absolute;
    	left:25px;
    	top:13px;
    	color:$textcolor;
    	opacity: 0.51;
    	&.focussed {
    		opacity:1;
    	}
    }
    p {
        margin-top: 0;
        padding: 0 15px;
    }

    #gallery {
        margin-top: 15px;
        margin-left: 15px;
    }

    .clickFeaturedText {
        display: none;
        color: $green;
        padding-left: 15px;
    }

    #gallery,
    #existing_photos {
        .uploaded_thumb {
            display: inline-block;
            position: relative;
            width: 135px;
            height: 135px;
            margin: 3px;
            border-radius: 5px;

            img {
                max-width: 135px;
                max-height: 135px;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 2px;
            }

            span.primaryImage {
                position: absolute;
                background: transparent;
                top: -17px;
                font-size: 12px;
                left: 0;
                color: $green;
                opacity: 0.8;
                font-weight: 600;
            }

            span.deleteFreshPhoto,
            span.deletePhoto {
                position: absolute;
                top: 0px;
                right: 0px;
                display: block;
                border: 1px solid #000;
                border-radius: 2px;
                width: 16px;
                height: 16px;
                line-height: 11px;
                text-align: center;
                background-color: $white;
                font-weight: 600;
                color: $red;
                cursor: pointer;
                z-index: 1000;
            }

        }
    }

    .button {
        display: inline-block;
        padding: 10px;
        background: #ccc;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .button:hover {
        background: #ddd;
    }

    #fileElem {
        display: none;
    }

    .progress {
        margin: 1%;
    }
}

