
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.progress-contain {
	padding: 15px;
	width:100%;
	.progress {
			height:8px;
			background-color: $blue;
			width: 100%;
			.progress-bar {
				height:8px;
				// color:$buttonblue;
				background-color: $buttonblue;
			}
	}
}
