
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

div.showMore.form-group {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    >a.simple {
        width: 150px !important;
        text-align: center;
        cursor: pointer;
    }
}

